import React, { useEffect, useState } from "react";
// Styles
import "./styles.scss";
// Components
import GetImageFromFirebaseStorage from "../../components/GetImageFromFirebaseStorage";
// Assets
import Banner from "../../assets/images/Three14-Banner-version-2023.1.2-36PPI.png";
import BannerDark from "../../assets/images/Three14-Banner-version-2023.1.2-light-f6f5fa.png";
import useLocalStorage from "use-local-storage";

const UnderConstructionPage = () => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  return (
    <div className="container">
      {/*<img*/}
      {/*  src={GetImageFromFirebaseStorage("Three14-Banner-Logo-White.png")}*/}
      {/*  className="logo"*/}
      {/*  alt="Three14, LLC logo"*/}
      {/*/>*/}
      {theme !== "dark" ? (
        <img src={Banner} className="logo" alt="Three14, LLC logo" />
      ) : (
        <img src={BannerDark} className="logo" alt="Three14, LLC logo" />
      )}

      <div className="rounded1 info">
        <h1>Under Construction</h1>

        <h2>Check back soon!</h2>
        <p className="title1">Last Update: May 29, 2023</p>
        {/*<p className="description">Last Update: May 3, 2023</p>*/}
        {/*<p>Last Update: May 3, 2023</p>*/}
      </div>
    </div>
  );
};

export default UnderConstructionPage;
