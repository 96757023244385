import React, { useEffect, useState } from "react";
// Styles
import "./styles.scss";
// Components
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import {FiMenu} from "react-icons/fi";

const MainLayout = (props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  // Handle drawer toggle
  const handleDrawerToggle = () => {
    setDrawerIsOpen(!drawerIsOpen);
  };

  return (
    <div className="main-layout-container" {...props}>
      <Sidebar drawerIsOpen={drawerIsOpen} handleDrawerToggle={handleDrawerToggle} />
      <div className="main-layout-section">
        <div className="drawer-header">
          <div className="drawer-open" onClick={handleDrawerToggle}>
            <FiMenu className="icon" />
          </div>
        </div>
        {props.children}
        <Footer />
      </div>
    </div>
  );
};

export default MainLayout;
