import React, { useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
// Styles
import "./default.scss";
// Layouts
import MainLayout from "./layouts/MainLayout";
// Pages
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";

function App() {
  // Under construction
  const underConstruction = true;

  // Get user theme choice
  const theme = window.localStorage.getItem("theme");

  useEffect(() => {
    const theme = window.localStorage.getItem("theme");
  }, [window.localStorage.getItem("theme")]);

  return (
    <div className="App" data-theme={theme}>
      <Routes>
        <Route
          path="/"
          element={
            <MainLayout data-theme={theme}>
              <HomePage underConstruction={underConstruction} />{" "}
            </MainLayout>
          }
        />
        <Route
          path="/about"
          element={
            <MainLayout data-theme={theme}>
              <AboutPage underConstruction={underConstruction} />{" "}
            </MainLayout>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
