import React from "react";
// Router
import { useNavigate } from "react-router-dom";
// Under Construction
import UnderConstructionPage from "../UnderConstructionPage/index";
// Styles
import "./styles.scss";
// Assets
import Banner from "../../assets/images/three14-website-light-gradiant-banner-version-2023.1.png";
import BannerDark from "../../assets/images/three14-website-dark-gradiant-banner-version-2023.1.png";
import useLocalStorage from "use-local-storage";

const HomePage = ({ underConstruction }) => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  const navigate = useNavigate();

  if (underConstruction === true) {
    return <UnderConstructionPage />;
  } else {
    return (
      <div className="home-page-container">
        <div
          className="rounded1 home-page-jumbotron"
          style={{
            backgroundImage: `url(${theme !== "dark" ? Banner : BannerDark})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1>Welcome to Three14</h1>
          <p className="description">
            We are a technology company that specializes in building custom
            software solutions for businesses of all sizes.
          </p>
          <button>Contact Us</button>
        </div>
      </div>
    );
  }
};

export default HomePage;
