import React, { useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";
// Styles
import "./styles.scss";
// Assets
import Logo from "../../assets/images/three14-logo-version-2023.1.4-light-f6f5fa-36PPI.png";
import LogoDark from "../../assets/images/three14-logo-version-2023.1.4-dark-232427-36PPI.png";
import LogoWords from "../../assets/images/Three14-Words-version-2023.1.2-light-f6f5fa-36PPI.png";
import LogoWordsDark from "../../assets/images/Three14-Words-version-2023.1.2-dark-232427-36PPI.png";

const Footer = () => {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  return (
    <div className="box1">
      <div className="footer-container">
        {theme !== "dark" ? (
          <img
            src={LogoDark}
            height={50}
            className="footer-logo"
            alt="Three14 Logo"
          />
        ) : (
          <img
            src={Logo}
            height={50}
            className="footer-logo"
            alt="Three14 Logo"
          />
        )}
        <p className="body1" style={{ marginBottom: "20px" }}>
          Three14 is a technology company that specializes in building custom
          software solutions for businesses of all sizes.
        </p>
        <p className="body1" style={{ marginBottom: "20px" }}>
          All content and materials on this website are protected by copyright
          and other intellectual property laws. All rights reserved. Any
          unauthorized use, reproduction or distribution of this material is
          strictly prohibited and may result in legal action.
        </p>
        <p className="body1" style={{ marginBottom: "40px" }}>
          All Three14 LLC branding, slogan or other material cannot be used or
          distributed in any way, shape, or form.
        </p>
        {theme !== "dark" ? (
          <img
            src={LogoWordsDark}
            height={55}
            className="footer-logo"
            alt="Three14 Logo"
          />
        ) : (
          <img
            src={LogoWords}
            height={55}
            className="footer-logo"
            alt="Three14 Logo"
          />
        )}
      </div>
    </div>
  );
};

export default Footer;
