import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UnderConstructionPage from "../UnderConstructionPage";

const AboutPage = ({ underConstruction }) => {
  const navigate = useNavigate();

  if (underConstruction === true) {
    return <UnderConstructionPage />;
  } else {
    return (
      <div className="about-page-container">
        <p className="body1" style={{ marginBottom: "20px" }}>
          Welcome to Three14 LLC, where we combine innovative ideas with expert
          execution to deliver outstanding results. Our team of experienced
          professionals is dedicated to providing top-notch services that meet
          your business needs and exceed your expectations.
        </p>
        <p className="body1" style={{ marginBottom: "20px" }}>
          {" "}
          At Three14 LLC, we specialize in creating innovative software
          solutions. We understand that every business is unique, which is why
          we take a customized approach to every project. Our team works closely
          with you to identify your specific needs and create tailored solutions
          that help you achieve your goals.
        </p>
        <p className="body1" style={{ marginBottom: "20px" }}>
          {" "}
          We pride ourselves on our commitment to excellence and our ability to
          deliver results that matter. Our team is passionate about what we do,
          and we are always looking for new and better ways to serve our
          clients.
        </p>
        <p className="body1">
          {" "}
          Whether you're looking to start from scratch, or simply want to
          improve your current operations, Three14 LLC is here to help. Contact
          us today to learn more about how we can help you take your business to
          the next level.
        </p>
      </div>
    );
  }
};

export default AboutPage;
