import React, { useEffect, useState } from "react";
import useLocalStorage from "use-local-storage";
import { useRef } from "react";
// Router
import { Link, useLocation } from "react-router-dom";
// Icons
import { FiHome, FiInfo, FiMenu, FiMoon, FiSun } from "react-icons/fi";
import LightLogo from "../../assets/images/three14-logo-version-2023.1.4-light-f6f5fa-36PPI.png";
import DarkLogo from "../../assets/images/three14-logo-version-2023.1.4-dark-232427-36PPI.png";

// Styles
import "./styles.scss";

const Sidebar = ({ drawerIsOpen, handleDrawerToggle }) => {
  let location = useLocation();

  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );

  // Handle theme switch
  const switchTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    window.location.reload(true);
  };

  // Get the current window width
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  return (
    <>
      <div className="sidebar-container" data-theme={theme}>
        <div className="sidebar-buttons">
          <Link to="/">
            <div className="sidebar-button">
              <div
                className={
                  location.pathname === "/"
                    ? "icon-container icon-selected"
                    : "icon-container"
                }
              >
                {theme !== "dark" ? (
                  <img
                    src={DarkLogo}
                    height={28}
                    width={28}
                    className="homeLogoButton"
                    alt="Home Logo"
                  />
                ) : (
                  <img
                    src={LightLogo}
                    height={28}
                    width={28}
                    className="homeLogoButton"
                    alt="Home Logo"
                  />
                )}
              </div>
              <p className="label1">Home</p>
            </div>
          </Link>

          <Link to="/about">
            <div className="sidebar-button">
              <div
                className={
                  location.pathname === "/about"
                    ? "icon-container icon-selected"
                    : "icon-container"
                }
              >
                <FiInfo className="icon" />
              </div>
              <p className="label1">About</p>
            </div>
          </Link>
        </div>
        <div className="sidebar-button">
          <div className="icon-container theme" onClick={switchTheme}>
            {theme !== "dark" ? (
              <FiMoon className="icon button-theme" />
            ) : (
              <FiSun className="icon button-theme" />
            )}
          </div>
        </div>
      </div>

      <div className={drawerIsOpen ? "drawer openDrawer" : "drawer"}>
        <div className="drawer-container">
          <div className="drawer-close" onClick={handleDrawerToggle}>
            <FiMenu className="icon" />
          </div>

          <Link to="/">
            <div
              className={
                location.pathname === "/"
                  ? "drawer-button icon-selected"
                  : "drawer-button"
              }
            >
              {theme !== "dark" ? (
                <img
                  src={DarkLogo}
                  height={28}
                  width={28}
                  className="homeLogoButton"
                  alt="Home Logo"
                />
              ) : (
                <img
                  src={LightLogo}
                  height={28}
                  width={28}
                  className="homeLogoButton"
                  alt="Home Logo"
                />
              )}
              <p className="label1">Home</p>
            </div>
          </Link>

          <Link to="/about">
            <div
              className={
                location.pathname === "/about"
                  ? "drawer-button icon-selected"
                  : "drawer-button"
              }
            >
              <FiInfo className="icon" />

              <p className="label1">About</p>
            </div>
          </Link>
        </div>

        <div className="drawer-theme toggle" onClick={switchTheme}>
          {theme !== "dark" ? (
            <>
              <FiMoon className="icon" />
              <p>Switch to dark mode</p>
            </>
          ) : (
            <>
              <FiSun className="icon" />
              <p>Switch to light mode</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
